<script setup>
import {Link} from "@inertiajs/vue3";
import Loader from "@/Components/Loader.vue";

defineProps({
    type: {
        type: String,
        default: 'submit',
    },
    // optional, renders anchor tag when set
    href: String,
    loading: Boolean,
});

const classes = "inline-flex items-center justify-center px-4 py-2 bg-blue-500 disabled:bg-blue-500 disabled:opacity-50 dark:bg-blue-200 border border-transparent rounded-md font-semibold text-xs text-white dark:text-blue-800 tracking-widest shadow-sm hover:bg-blue-700 dark:hover:bg-white focus:bg-blue-700 dark:focus:bg-white active:bg-blue-900 dark:active:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-blue-800 transition ease-in-out duration-150"
</script>

<template>
    <Link v-if="href" :href="href" :class="classes">
        <Loader v-if="loading" :loading="loading" class="h-full w-4 mr-2"/>

        <slot />
    </Link>
    <button v-else :type="type" :class="classes">
        <Loader v-if="loading" :loading="loading" class="h-full w-4 mr-2"/>

        <slot />
    </button>
</template>
